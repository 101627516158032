
import { defineComponent, onMounted, ref, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { hideModal } from "@/core/helpers/dom";

import DaftarPSP from "@/components/1crm/siswa/daftar-psp.vue";

import moment from "moment";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import JsonViewer from "vue-json-viewer";

import { Modal } from "bootstrap";
import Multiselect from "@vueform/multiselect";
import { identity } from "@fullcalendar/common";
import JwtService from "@/core/services/JwtService";
import Message from "element-plus/lib/el-message/src/message";
// import menuu from "/core/config/MenuRoleLegal.ts";

import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    DaftarPSP,
    Loading,
    Multiselect,
    JsonViewer,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      isLoading: false,
      role: "",
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      migrasiModalRef: ref<null | HTMLElement>(null),
      disableButton: false,
      tableData: [],
      listSekolah: [],
      listStatus: ["Terbaca", "Belum Dibaca"],
      listStatuss: ["Terbayar", "Konfirmasi"],
      statusData: "",
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      title: "",
      type: "",
      email: "",
      status: "",
      schoolId: "",
      payment_status: "",
      other_filter: "other_filter=manual",
      roleuser: "",
      detail: {
        createTime: "",
        email: "",
        message: "",
        message_asli: "",
        finance_confirmation_id: "",
        legal_confirmation_id: "",
        status: "",
        title: "",
        payment_status: "",
        other_filter: "",
        _id: "",
        type: "",
      },

      isShowButtonKonfirmasiLegal: false,
      isShowButtonKonfirmasiFinance: false,
    };
  },

  beforeMount() {
    // this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
    // this.getData(this.paging.size, this.paging.page, '');

    this.getListSekolah();
    this.roleuser = JSON.parse(localStorage.getItem("user_account")!).role;

    this.getData(this.paging.size, this.paging.page, "");
    this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page, from) {
      this.isLoading = true;

      var roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
      var role = "";
      if (roleuser == "ADMIN_LEGAL") {
        role = "legal";
      } else if (roleuser == "ADMIN_SCHOOL") {
        role = "sekolah";
      } else if (roleuser == "ADMIN_FINANCE") {
        role = "finance";
      } else if (roleuser == "IMPLEMENTATOR_LEADER") {
        role = "";
      } else if (roleuser == "IMPLEMENTATOR_MEMBER") {
        role = "";
      } else if (
        roleuser == "MARKETING_LEADER" ||
        roleuser == "MARKETING_MEMBER"
      ) {
        role = "AKTIVASI";
        // this.title = "AKTIVASI AKUN ADMIN";
      } else if (
        roleuser === "SALES_LEADER" ||
        roleuser === "SALES_AREA" ||
        roleuser === "CS_LEADER" ||
        roleuser === "CS_MEMBER"
      ) {
        role = "sales";
      } else if (roleuser === "SUPER_ADMIN") {
        role = "";
      } else {
        this.isLoading = false;
        return;
      }
      console.log("roleuser", roleuser);
      this.role = role;
      const toast = useToast();

      this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);

      let search1 = "";
      if (this.schoolId) {
        search1 = "companyId=" + this.schoolId + "&";
      } else {
        search1;
      }

      let search2 = "";
      if (this.title) {
        search2 = "title=" + this.title + "&";
      } else {
        search2;
      }
      let search9 = "";
      if (this.title) {
        search9 = "title=" + this.title + "&";
      } else {
        search9;
      }
      let search11 = "";
      if (this.title) {
        search11 = "title=" + this.title + "&";
      } else {
        search11;
      }
      let search3 = "";
      if (this.type) {
        search3 = "type=" + this.type + "&";
      } else {
        search3;
      }

      let search4 = "";
      if (this.email) {
        search4 = "email=" + this.email + "&";
      } else {
        search4;
      }
      if (roleuser == "MARKETING_LEADER" || roleuser == "MARKETING_MEMBER") {
        search2 = "title=AKTIVASI AKUN ADMIN&";
      }

      if (roleuser == "ADMIN_FINANCE") {
        search9 = this.payment_status + "&";
      }
      let url = `crmv2/main_notification/notification/office/all?type=${role}&status=${this.status}&${search1}${search2}${search4}${search9}${search11}page=${page}&size=${size}&sort=updateTime&dir=-1`;
      if (
        roleuser === "SALES_LEADER" ||
        roleuser === "SALES_AREA" ||
        roleuser === "CS_LEADER" ||
        roleuser === "CS_MEMBER"
      )
        url = `crmv2/main_notification/notification/office/all?type=${role}%2Caktivasi&status=${this.status}&${search1}${search2}${search4}${search9}${search11}page=${page}&size=${size}&sort=updateTime&dir=-1`;
      if (roleuser === "MARKETING_LEADER" || roleuser === "MARKETING_MEMBER")
        url = `crmv2/main_notification/notification/office/all?type=${role}&status=${this.status}&${search1}${search2}${search4}${search9}${search11}page=${page}&size=${size}&sort=updateTime&dir=-1`;

      return ApiService.getWithoutSlug(`${url}`)
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.setReadNotif(data.content);
          // if (from == "filter") this.pilihStatus(this.status, data.content)
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    setReadNotif(items) {
      var data = JSON.stringify(items);
      var b = JSON.parse(data);
      b.forEach((element) => {
        if (element._id == "62b52216598ecab0c75d9fc1") {
          element.status = "WAIT";
        }
      });
    },

    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
        }
      );
    },

    pilihStatus(event, data) {
      // var cekstatus = event == "Terbaca" || event == 'READ' ? "READ" : "unread"
      // this.status = cekstatus
      console.log(event, data, this.status);
      var acoba = JSON.stringify(data);
      var sss = JSON.parse(acoba);
      let seleksi = reactive([]);
      let tes = [sss];
      this.getData(this.paging.size, this.paging.page, "");
    },

    pilihStatuspembayaran(event) {
      // var cekstatus = event == "Terbaca" || event == 'READ' ? "READ" : "unread"
      // this.status = cekstatus
      // var acoba = JSON.stringify(data)
      console.log(event.target.value);
      this.payment_status = event.target.value + "";
      this.getData(10, 0, "");
    },

    clickReset(event) {
      // var cekstatus = event == "" || event == '' ? "" : ""
      // this.schoolId = cekstatus

      console.log("rerst", event.target.value);
      localStorage.setItem("sekolahId", JSON.stringify(""));
      this.statusData = "";
      this.schoolId = "";
      // this.setItem("sekolahId")
      //  event.preventDefault();
      // remove existing saved config
      // localStorage.removeItem("schoolId");
      this.getData(this.paging.size, this.paging.page, "");
      // window.location.reload();
      event.preventDefault();
      // remove existing saved config
      localStorage.removeItem("");
      this.$forceUpdate();
      // window.location.reload();
    },

    pilihSekolah(event) {
      console.log("event", event);
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page, "");
    },

    // kosongkanSekolah() {
    //   this.schoolId = "";
    // },

    dateTime(value) {
      return moment(value).format("DD/MM/YYYY");
    },

    dateTime2(value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
    kembali() {
      const store = useStore();
      store.dispatch(Actions.NOTIFIKASI);
    },
    setNumberNotif(id) {
      // items.numbernotif = 15
      var data = JSON.stringify(this.tableData);
      var b = JSON.parse(data);
      b.forEach((element) => {
        if (element._id == id) {
          element.status = "READ";
          console.log(element);
        }
      });
      this.tableData = b;
      // ApiService.getWithoutSlug(
      //   "crmv2/main_notification/notification/office/all?" +
      //   `&type=${this.role}` +
      //   "&status=wait" +
      //   "&sort=createTime&dir=-1"
      // )
      //   .then(({ data }) => {
      //     localStorage.setItem("badgeNotiflegal", data.totalElements);
      //   })
    },
    readnotif(index, item) {
      ApiService.getWithoutSlug(
        `crmv2/main_notification/notification/office/${item._id}/read`
      )
        .then(({ data }) => {
          // localStorage.setItem("badgeNotiflegal", data.totalElements);
          this.detailNotif(item);
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    detailNotif(item) {
      this.isLoading = true;

      this.isShowButtonKonfirmasiLegal = false;
      this.isShowButtonKonfirmasiFinance = false;

      this.detail.message_asli = item.message;
      this.detail.finance_confirmation_id = item.finance_confirmation_id;
      this.detail.legal_confirmation_id = item.legal_confirmation_id;

      // var bedrooms = item.title.filter(name => name.includes('PERMINTAAN KONFIRMASI PEMBAYARAN MANUAL INVOICE'))
      // console.log(bedrooms)
      let result = item.title.substring(0, 47);
      // console.log(result)
      if (result == "PERMINTAAN KONFIRMASI PEMBAYARAN MANUAL INVOICE") {
        // var yy = JSON.stringify(item.message)

        var uu = item.message.replaceAll("<br>", "\n");
        var aa = uu;

        var myobj = JSON.parse(JSON.stringify(aa));
        // var tt = JSON.parse(aa)
        console.log(uu);
        this.detail.createTime = item.createTime;
        this.detail.email = item.email;
        this.detail.message = uu;
        this.detail.status = item.status;
        this.detail.title = item.title;
        this.detail._id = item._id;
        this.detail.type = item.type.join(", ");
        this.isLoading = false;
      } else {
        this.detail.createTime = item.createTime;
        this.detail.email = item.email;
        this.detail.message = item.message;
        this.detail.status = item.status;
        this.detail.title = item.title;
        this.detail._id = item._id;
        this.detail.type = item.type.join(", ");
        this.isLoading = false;
      }

      var role_adm = JSON.parse(localStorage.getItem("user_account")!).role;

      if (item.finance_confirmation_id) {
        console.log("id_finance_true");
        if (role_adm == "SUPER_ADMIN" || role_adm == "ADMIN_FINANCE") {
          this.isShowButtonKonfirmasiFinance = true;
        }
      }

      if (item.legal_confirmation_id) {
        console.log("id_legal_true");
        if (role_adm == "SUPER_ADMIN" || role_adm == "ADMIN_LEGAL") {
          this.isShowButtonKonfirmasiLegal = true;
        }
      }

      const modal = new Modal(document.getElementById("kt_modal_notif"));
      modal.show();
    },

    konfirmasiLegal(dataDetail) {
      console.log(dataDetail);
      localStorage.setItem(
        "idKonfirmasiLegalfromNotif",
        dataDetail.legal_confirmation_id
      );
      localStorage.setItem(
        "tglKonfirmasiLegalfromNotif",
        moment(dataDetail.createTime).format("YYYY-MM-DD")
      );
      localStorage.setItem("statusKonfirmasiLegalfromNotif", "true");
      // this.$router.push({ path: '/superadmin/legal/konfirmasiLegal' });
      setTimeout(
        () => this.$router.push({ path: "/superadmin/legal/konfirmasiLegal" }),
        1000
      );
    },
    konfirmasiPembayaran(dataDetail) {
      console.log(dataDetail);
      localStorage.setItem(
        "idKonfirmasiFinancefromNotif",
        dataDetail.finance_confirmation_id
      );
      localStorage.setItem(
        "tglKonfirmasiFinancefromNotif",
        moment(dataDetail.createTime).format("YYYY-MM-DD")
      );
      localStorage.setItem("statusKonfirmasiFinancefromNotif", "true");
      // this.$router.push({ path: '/superadmin/konfirmasi-invoice' });
      setTimeout(
        () => this.$router.push({ path: "/superadmin/konfirmasi-invoice" }),
        1000
      );
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page, "");
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page, "");
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page, "");
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page, "");
    },
  },
});
